.stat-changer {
  width: 2em;
  text-align: center;
  display: inline-block;
}

.stat-name {
  width: 6em;
  display: inline-block;
}

.item-name {
  width: 8em;
  display: inline-block;
}

.item-attribute {
  display: inline-block;
}

.stat-button {
  width: 50px;
}
